import { useState } from "react";

export default function useToken() {
  const getToken = () => {
    try {
      const tokenString = localStorage.getItem("token");
      const userToken = JSON.parse(tokenString);
      return userToken?.token;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const [token, setToken] = useState(getToken());

  const saveToken = (userToken) => {
    localStorage.setItem("token", JSON.stringify(userToken));

    if (typeof userToken != "undefined") setToken(userToken.token);
  };

  return {
    setToken: saveToken,
    token,
  };
}
